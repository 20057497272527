import { VueWizard } from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ name: 'agent-card' })
export default class AgentCard extends VueWizard {
    @Prop() readonly agent!: any;
    @Prop() readonly detailsTarget!: string;
    @Prop() readonly chatsTarget!: string;

    getInitials(name: any): string {
        let first_letter = '';
        let second_letter = '';
        if (name.firstName && name.lastName) {
            first_letter = name.firstName.split('')[0].toUpperCase();
            second_letter = name.lastName.split('')[0].toUpperCase();
        } else if (name.firstName && !name.lastName) {
            first_letter = name.firstName.split('')[0].toUpperCase();
        } else if (!name.firstName && name.lastName) {
            second_letter = name.lastName.split('')[0].toUpperCase();
        }

        return first_letter + second_letter;
    }

    showDetails() {
        this.$emit('details', {
            agentId: this.agent._id,
            agentProjects: this.agent.projects,
            agentUsername: this.agent.usrage[0].username
        })
    }
    showChats() {


        console.log('show chats');
        this.$emit('chats', {
            agentId: this.agent,
            agentProjects: this.agent.projects,
            agentUsername: this.agent.usrage[0].username
        })
    }



}