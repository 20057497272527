import { Component, } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { BootstrapVue } from 'bootstrap-vue'
// Components
import AgentsAdministrator from "@/app/components/agents-management/agents-administrator/AgentsAdministrator.vue";
import AdminNavbar from "@/app/components/admin-navbar/AdminNavbar.vue";
import QuestionsManagement from "@/app/components/agents-management/questions-management/QuestionsManagement.vue";
import NavBar from "@/app/components/shared/nav-bar/NavBar.vue";

@Component({
    name: "agents-management",
    components: { AgentsAdministrator, AdminNavbar, QuestionsManagement,NavBar },
})
export default class AgentsManagement extends VueWizard {
    public activeWindow = 1
}
