import { VueWizard } from "@/vue-wizard";
import { Component } from "vue-property-decorator";
import { BASE_URL_MANAGER } from "@/config";
import Axios from "axios";
import QuickResponseRow from '@/app/components/agents-management/dedicated-components/quick-response-row/QuickResponseRow.vue'
import NewQuickResponseModal from '@/app/components/agents-management/dedicated-components/new-quick-response-modal/NewQuickResponseModal.vue'
import EditQuickResponseModal from '@/app/components/agents-management/dedicated-components/edit-quick-response-modal/EditQuickResponseModal.vue'
import * as bootstrap from "bootstrap";
import $ from 'jquery';
import Vue from 'vue';
import { IUser } from "@/app/store/modules/user/user.models";
const _ = require('lodash');
import { store, storeTypes } from "@/app/store";

@Component({
    name: "questions-management",
    components: { QuickResponseRow, NewQuickResponseModal, EditQuickResponseModal }
})

export default class QuestionsManagement extends VueWizard {

    public projects: any = [];
    public project_id = '';
    public topics: any = [];
    public quickResponses: any = [];
    public quickResponsesIds: any = [];
    public fieldToEdit = ''
    public quickResponseToEdit = '';
    public quickResponseModalKey: any = 0;
    public EventBus: Vue = new Vue();
    public userLoged: IUser = store.state.user!.user;

    mounted() {

        this.userLoged = store.state.user!.user;
        if (this.userLoged != null) {
            console.log('this.userLoged ', this.userLoged.company);
            this.getCurrentProjects();
            this.getTopicsByProject();
            this.getQuickResponses();
        }

    }

    async getCurrentProjects() {
        let url = `${BASE_URL_MANAGER}/project/company/${this.userLoged.company._id}`;
        Axios(url).then(res => {
            this.projects = res.data.projects;
        })
    }

    getTopicsByProject() {
        let url = `${BASE_URL_MANAGER}/topic/${this.project_id}`;
        return new Promise(resolve => {
            Axios.get(url).then(res => {
                this.topics = res.data.projectTopics;
                resolve();
            });
        })

    }

    updateProject() {
        this.getTopicsByProject().then(() => {
            this.getQuickResponses().then(() => {
                this.quickResponseIdsForCurrentProyect();
            });
        });
    }

    getQuickResponses() {
        let url = `${BASE_URL_MANAGER}/quickResponse/topic`;

        return new Promise(resolve => {
            Axios.get(url).then(res => {
                this.quickResponses = res.data;
                resolve();
            });
        });
    }

    enableQuickResponseModal(id: string) {
        this.EventBus.$emit('reload-modal');
        this.enable(id);
    }


    enable(id: string) {
        $('#' + id).modal('show');
    }

    editField(values: any) {
        let elementId = values.id;
        this.fieldToEdit = values.field;
        this.quickResponseToEdit = _.cloneDeep(values.quickResponse);
        this.enable(elementId);
    }

    quickResponseIdsForCurrentProyect() {
        this.quickResponsesIds = [];
        this.topics.forEach((topic: { _id: any; }) => {
            this.quickResponses.forEach((quickResponse: { topic: string | any[]; _id: any; }) => {
                if (quickResponse.topic.includes(topic._id) && !this.quickResponsesIds.includes(quickResponse._id)) {
                    this.quickResponsesIds.push(quickResponse._id);
                }
            });
        });
    }



}
