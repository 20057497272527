import Axios from "axios";
import {VueWizard} from "@/vue-wizard";
import Component from 'vue-class-component';
import { BASE_URL_MANAGER } from "@/config";
import { Prop } from 'vue-property-decorator';
import * as bootstrap from "bootstrap"
import $ from 'jquery';


@Component({
    name: 'edit-quick-response-modal'
})
export default class EditQuickResponseModal extends VueWizard{
    @Prop() readonly elementId!: string;
    @Prop({default: true}) readonly autoDisable!: boolean;
    @Prop() readonly parentElementId!: string;
    @Prop() readonly field!: string;
    @Prop() readonly quickResponse!: any;

    disable(){
        if(this.autoDisable){
            $('#' + this.elementId).modal('hide');
        }else{
            this.elementDisableEnable('#' + this.elementId, '#' + this.parentElementId)
        }
    }

    elementDisableEnable(elementId: string, enableId: any): void {
        let modal: any = $(elementId);
        modal.modal('hide');
        modal = $(enableId);
        modal.modal('show');
    }

    editQuickResponse(){
        let url = `${BASE_URL_MANAGER}/quickResponse/topic/${this.quickResponse._id}`;
        try{
            Axios.put(url, this.quickResponse).then(res =>{
                if(res.status == 200){
                    this.$notification.success({
                        placement: "bottomRight",
                        message: "Respuesta rápida guardada exitosamente",
                        description: "Los datos han sido guardados"
                        
                    });
                }else{
                    this.$notification.error({
                        placement: "bottomRight",
                        message: `Hubo un error al guardar al información ${res.status}`,
                        description: `Error: ${res.status}`
                    })
                }
                this.disable();
                this.$emit('updated');
            });
            

        }catch(err){
            throw err;
        }
        
    }
}