import { render, staticRenderFns } from "./AgentsManagement.vue?vue&type=template&id=71e7bb35&scoped=true&"
import script from "./agents-management.ts?vue&type=script&lang=ts&"
export * from "./agents-management.ts?vue&type=script&lang=ts&"
import style0 from "./AgentsManagement.vue?vue&type=style&index=0&id=71e7bb35&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e7bb35",
  null
  
)

export default component.exports