import { VueWizard } from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as bootstrap from "bootstrap"
import $ from 'jquery';

import ConversationsList from "@/app/components/shared/conversations-list/ConversationsList.vue";


@Component({
    name: 'conversation-list-modal',
    components: { ConversationsList }
})
export default class ConversationListModal extends VueWizard {

    @Prop() readonly elementId!: string;
    @Prop() readonly currentConversation!: any;
    @Prop() readonly eventBus!: any;
    @Prop() readonly agentConversations!: any;
    @Prop() readonly currentCloseConversations!: any;
    @Prop({ default: true }) readonly autoDisable!: boolean;
    @Prop() readonly parentElementId!: string;

    disable() {
        if (this.autoDisable) {
            $('#' + this.elementId).modal('hide');
        } else {
            this.elementDisableEnable('#' + this.elementId, '#' + this.parentElementId)
        }
    }

    closeConversationModal() {
        this.disable();
        this.$emit('close');
    }

    elementDisableEnable(elementId: string, enableId: any): void {
        let modal: any = $(elementId);
        modal.modal('hide');
        modal = $(enableId);
        modal.modal('show');
    }

    getUserPicture(item: any): any {
        let profileurl = null;
        let conversation;

        if (item.variables) {
            conversation = item;
        } else {
            conversation = this.agentConversations.find((conv: any) => conv._id == item.conversation);
        }

        if (conversation != undefined && conversation.variables!=undefined && conversation.variables.CONSUMER_PROFILE_PICTURE) {
            profileurl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
        }
        return profileurl;
    }
}