import { VueWizard } from "@/vue-wizard";
import { Conversation } from '@/app/store/modules/conversation/conversation.models'
import { store, storeTypes } from "@/app/store";
import Component from 'vue-class-component';
import ConversationListElement from '@/app/components/shared/conversation-list-element/ConversationListElement.vue'
import ConversationModal from '@/app/components/shared/conversation-modal/ConversationModal.vue'
import { Prop } from 'vue-property-decorator';
import * as bootstrap from "bootstrap"
import $ from 'jquery';

@Component({
    name: 'conversations-list',
    components: { ConversationListElement, ConversationModal }
})
export default class ConversationsList extends VueWizard {
    @Prop() agentConversations!: any;
    @Prop() currentCloseConversations!: any;
    @Prop() readonly parentElementId!: string;

    getConversation(item: Conversation): void {
        store.commit(storeTypes.conversation.mutations.setCurrentConversation(item));
    }

    elementDisableEnable(elementId: string, enableId: any): void {
        let modal: any = $(elementId);
        modal.modal('hide');
        modal = $(enableId);
        modal.modal('show');
    }
}