/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VueWizard } from "@/vue-wizard";
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/app/store/modules/user/user.models";
import { store, storeTypes } from "@/app/store";
import Axios from "axios";
import { BASE_URL_MANAGER } from "@/config";

import $ from "jquery";
import AgentDetails from "@/app/components/agents-management/dedicated-components/agent-details/AgentDetails.vue";
import PopConfirm from '@/app/components/shared/pop-confirm/PopConfirm.vue';
import ConversationModal from '@/app/components/shared/conversation-modal/ConversationModal.vue';
import ConversationListModal from '@/app/components/shared/conversation-list-modal/ConversationListModal.vue';
import UserNameModal from '@/app/components/agents-management/dedicated-components/user-name-modal/UserNameModal.vue';
import AgentCardDeck from '@/app/components/agents-management/dedicated-components/agent-card-deck/AgentCardDeck.vue';
import AgentCreateForm from '@/app/components/agents-management/dedicated-components/agent-create-form/AgentCreateForm.vue';
const _ = require("lodash");

@Component({
    name: "agents-administrator",
    components: { PopConfirm, ConversationModal, ConversationListModal, UserNameModal, AgentCardDeck, AgentDetails, AgentCreateForm }
})
export default class AgentsAdministrator extends VueWizard {

    public modalConversation = false;
    public emptyText = { emptyText: "Sin conversaciones" };
    public EventBus: Vue = new Vue();
    public visible = false;
    public modalvisible = false;
    public usrData: any = [];
    public agtUsrData: any = [];
    public agentDetails: any = [];
    public projectAgent: any = [];
    public projectTopics: any = [];
    public agentName = "";
    public user: IUser | null = null;
    public userLoged: IUser | null = store.state.user!.user;
    public edit = false;
    public agentNewName = "";
    public agentNewLastName = "";
    public agentNewPassword = "";

    public loadingInfo: boolean = false;

    public pStyle: any = {
        marginBottom: "16px",
        textTransform: "capitalize",
        letterSpacing: "1px"
    };

    public pStyle2: any = {
        marginBottom: "24px"
    };

    get currentConversation(): any {
        return store.state.conversation!.currentConversation;
    }

    get agentConversations(): any {
        let conversations = this.agentOpenConversations.concat(Array.from(this.agentCloseConversations).reverse());
        return _.uniqBy(conversations, "currentConsumer");
    }

    get agentOpenConversations(): any {
        return store.state.agent!.agentOpenConversations;
    }

    get agentCloseConversations(): any {
        return store.state.agent!.agentCloseConversations;
    }

    get currentCloseConversations(): any {
        return store.state.agent!.agentCloseConversations
            .filter(conversation => conversation.currentConsumer == store.state.conversation!.currentConversation.currentConsumer);
    }

    async mounted(): Promise<any> {

        this.userLoged = store.state.user!.user;


        await this.getAgentInfo(store.state.user!.user.company._id);
    }

    showNewAgentModal(): void {
        this.modalvisible = true;
        this.getUsrInfo();
    }

    toggleVisible(): void {
        this.visible = !this.visible;
    }

    showAgentDetails(values: any): void {
        let agentId = values.agentId;
        let project = values.agentProjects;
        let agent = values.agentUsername;

        this.user = null;
        this.agentDetails = [];
        this.projectAgent = {};
        this.projectTopics = [];
        store.commit(storeTypes.agent.mutations.reSetAgentCloseConversations(""));
        this.getAgentProject(project);
        this.getProjectTopics(project);
        Axios(`${BASE_URL_MANAGER}/agent/${agentId}`)
            .then(async res => {
                this.agentDetails = [res.data.agent];
                //await store.dispatch(storeTypes.agent.actions.loadAgentCloseConversations(agentId));
                //await store.dispatch(storeTypes.agent.actions.loadAgentOpenConversations(agentId));
                await this.getUserAgentData(this.agentDetails[0]!.user);
                this.visible = true
            });
        this.agentName = agent;
    }
    showAgentConversations(values: any): void {


        let agentId = values.agentId;
        let project = values.agentProjects;
        let agent = values.agentUsername;


        this.user = null;
        this.agentDetails = [];
        this.projectAgent = {};
        this.projectTopics = [];
        store.commit(storeTypes.agent.mutations.reSetAgentCloseConversations(""));
        this.getAgentProject(project);
        this.getProjectTopics(project);
        Axios(`${BASE_URL_MANAGER}/agent/${agentId._id}`)
            .then(async res => {
                this.agentDetails = [res.data.agent];
                await store.dispatch(storeTypes.agent.actions.loadAgentCloseConversations({ agent_id: agentId._id, page: 0 }));
                await store.dispatch(storeTypes.agent.actions.loadAgentOpenConversations({ agent_id: agentId._id, page: 0, limit: 50 }));
                await this.getUserAgentData(this.agentDetails[0].user);
                this.visible = true
            });
        this.agentName = agent;
    }


    onClose(): void {
        this.visible = false;
    }

    getAgentInfo(company: any): void {



        Axios(`${BASE_URL_MANAGER}/agent/users/relAgents/${company}`)
            .then(res => this.agtUsrData = res.data.agents);
    }

    getAgentProject(projectId: any): void {
        projectId.forEach((ele: any) => {
            Axios(`${BASE_URL_MANAGER}/project/${ele}`)
                .then(res => this.projectAgent = res.data);
        });
    }

    changeUserInfo(values: any): void {

        this.agentNewName = values.agentNewName
        this.agentNewLastName = values.agentNewLastName
        this.agentNewPassword = values.agentNewPassword
        console.log(' this.agentNewPassword', this.agentNewPassword);

        let userId = values.userId

        let user = this.user;


        if (user && user.name) {
            user.name.firstName = this.agentNewName;
            user.name.lastName = this.agentNewLastName;

            this.agtUsrData.map((agent: any) => {
                if (user && user.name && agent.usrage[0]._id == user._id) {
                    agent.usrage[0].name.firstName = user.name.firstName;
                    agent.usrage[0].name.lastName = user.name.lastName;
                }
            })
        }


        if (user && user.name) {
            let dataToSend: any = {

                name: { firstName: user.name.firstName, lastName: user.name.lastName }
            }
            if (this.agentNewPassword != '' && this.agentNewPassword != null) {
                dataToSend.password = this.agentNewPassword;
            }

            Axios.put(`${BASE_URL_MANAGER}/user/${userId}`, dataToSend).then(async (resp) => {
                console.log(resp.data);

                if (resp.data.success === true) {
                    this.$notification.success({
                        placement: "bottomRight",
                        message: "Agente modificado exitosamente",
                        description: "Los datos han sido guardados"

                    });
                    this.getUserAgentData(resp.data.user._id);

                } else {
                    this.$notification.success({
                        placement: "bottomRight",
                        message: "Error",
                        description: "Ha ocurrido un error al guardar los datos "+resp.data!.message

                    });
                }
            });
        }
    }

    getProjectTopics(projectId: string): void {
        Axios(`${BASE_URL_MANAGER}/topic/${projectId}`)
            .then(res => this.projectTopics = res.data.projectTopics);
    }

    getAgendData(agent_id: string): void {
        Axios(`${BASE_URL_MANAGER}/agent/${agent_id}`)
            .then(res => this.agentDetails = [res.data.agent]);
    }

    getUserAgentData(user_id: string): void {
        Axios(`${BASE_URL_MANAGER}/user/${user_id}`)
            .then(res => {
                this.user = res.data.user;
                if (this.user && this.user.name) {
                    this.agentNewName = this.user.name.firstName;
                    this.agentNewLastName = this.user.name.lastName;
                }
            });
    }

    unSuscribeAgent(values: any): void {
        let topic = values.topic;
        let agent = values.agent;

        this.loadingInfo = true;
        Axios.delete(`${BASE_URL_MANAGER}/topic/${topic._id}/agents/${agent._id}`)
            .then(() => {
                this.$notification.open({
                    message: "Se ha eliminado la suscripcion del topico al agente",
                    description: "El agente ha sido eliminado con exito del topico",
                    style: {
                        background: "#32ad3f",
                        color: "#fff"
                    }
                });

                this.getAgentInfo(store.state.user!.user.company._id);
                this.getAgendData(agent._id);
                this.loadingInfo = false;
            });
    }

    suscribeAgent(values: any): void {
        let topic = values.topic;
        let agent = values.agent;

        this.loadingInfo = true;

        Axios.patch(`${BASE_URL_MANAGER}/topic/${topic._id}/agents/${agent._id}`)
            .then(() => {
                this.$notification.open({
                    message: "Se ha suscrito el agente",
                    description: "El agente ha sido suscrito con exito al topico",
                    style: {
                        background: "#32ad3f",
                        color: "#fff"
                    }
                });
                console.log('store.state.user!.user.company._id', store.state.user!.user.company._id);
                this.getAgentInfo(store.state.user!.user.company._id);
                this.getAgendData(agent._id);
                this.loadingInfo = false;
            });

    }

    elementDisable(elementId: string): void {
        const modal: any = $(elementId);
        modal.modal('hide');
    }

    disableAgent(disableAgentId: string, project: string, agent: any, state: boolean): void {
        Axios.put(`${BASE_URL_MANAGER}/agent/${disableAgentId}/disable/${state}`)
            .then((res: any) => {
                const result = this.agtUsrData.filter((agent: any) => agent._id === disableAgentId);
                result[0].disable = state;
                this.agentDetails[0]!.disable = state;
                this.$notification.open({
                    message: "Estado del agente cambiado",
                    description: "El estado del agente cambio con exito!",
                    style: {
                        background: "#009dff",
                        color: "#ffffff",
                    }
                });


                this.showAgentDetails({
                    agentId: disableAgentId,
                    agentProjects: project,
                    agetnUsername: agent
                });
            });

    }

    deleteAgent(disableAgentId: string, project: string, agent: any): void {
        Axios.delete(`${BASE_URL_MANAGER}/agent/${disableAgentId}/delete`)
            .then((res: any) => {

                if (res.data.success === true) {
                    this.$notification.open({
                        message: "El agente ha sido eliminado",
                        description: "Agente eliminado con exito!",
                        style: {
                            background: "#009dff",
                            color: "#ffffff",
                        }
                    });
                    this.elementDisable('modalAgentDetails');
                    this.getAgentInfo(store.state.user!.user.company._id);
                } else {
                    this.$notification.error({
                        placement: "bottomRight",
                        message: `Hubo un error al guardar al información`,
                        description: `Error: `
                    });
                }

            }).catch((e) => {
                this.$notification.error({
                    placement: "bottomRight",
                    message: `Hubo un error al guardar al información`,
                    description: `Error: ${e}`
                });
            });

    }

    getUsrInfo(): void {
        Axios(`${BASE_URL_MANAGER}/user/all/${store.state.user!.user.company._id}`)
            .then(res => this.usrData = res.data.users)
            .catch(err => {
                throw err;
            });
    }

    getSuscriptionClass(item: any, name: string): any {

        let subscriptions = item.subscriptions;
        let styleClass = "";

        subscriptions.forEach((itemArray: any) => {

            let splited = itemArray.split("_");
            let unless = "";

            for (let i = 1; i < splited.length; i++) {
                unless += splited[i];
                if (i != splited.length - 1) {
                    unless += "_";
                }
            }

            if (name === unless) {
                styleClass = "ant-btn-primary";
                return true;
            }

        });

        return styleClass;
    }

    existsUserName(): boolean {
        return this.user != null
    }

    isEmpty(obj: any): boolean {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    openAgentCreateModal(id: string,) {
        this.EventBus.$emit('reload-modal');
        this.enable(id);
    }

    enable(id: string) {
        $('#' + id).modal('show');
    }










}